.courses_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .courses_list-item {
    width: 48%;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    border-color: #d9d9d9;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .courses_list-item li{
    list-style-position: inside;
    padding-left: 2rem;
    text-indent: -1.5rem;

  }
  
  .courses_list-item ul{
    padding-top: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .courses_list{
        padding: 1rem;
    }
    .courses_list-item {
      width: 100%;

    }
    .courses_list-item li{
    text-indent: -1.5rem;

    }
  }