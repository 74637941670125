@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(90deg, rgba(30, 190, 88, 1) 0%, rgba(48, 100, 44, 1) 100%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #f2f2f2;
  --color-footer : #efefef;
  --color-blog: #00ffa6;
  --color-text: #161616;
  --color-subtext: #2ea44f;
  --color-darker-green: #11702c;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  text-decoration: none;
  font-family: var(--font-family);
}

body {
  background-color: var(--color-bg);
}
h2{
  margin-bottom: 1rem;
}
p{
  line-height: 2  rem;
}

.button__default{
  -webkit-appearance: none;
          appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 32px;
  margin-top: 2rem;
  text-align: center;
  text-decoration: none;
  vertical-align: left;
}

.section__padding {
  padding: 4rem 6rem;
}
@media screen and (max-width:768px) {
  .section__padding{
    padding: 1rem 1rem;
  }
  
}
.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    background-color: white;
    /* box-shadow: 0px 2px 10px #C2C2C2; */
}

.navbar-links{
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.navbar-links_logo{
    display: flex;
    flex: 1 1;
}

.navbar-links_logo img{
    width: 56px;
    margin-right: 1rem;
}

.navbar-links-logo-text{
    display: flex;
    align-self: center;
    font-weight: bold;
    font-size: large;
}

.navbar-links-items{
    display: flex;
    flex-direction: row;
    font-size: medium;
    font-weight: 500;
    grid-gap: 2rem;
    gap: 2rem;
}

.navbar-links-items a {
    color: var(--color-text);
}

.navbar-links-items-button{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-menu{
    display: none;
}

.navbar-button button{
      -webkit-appearance: none;
              appearance: none;
      background-color: #2ea44f;
      border: 1px solid rgba(27, 31, 35, .15);
      border-radius: 6px;
      box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      font-weight: 300;
      line-height: 20px;
      padding: 10px 28px;
      position: relative;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      white-space: nowrap;
    }

    @media screen and (max-width: 1050px){
        .navbar-links-items {
            display: none;
        }
        .navbar-menu{
            display: flex;
        }
    }

    @media screen and (max-width: 700px){
        .navbar {
            padding: 2rem 4rem;
        }
    }

    .navbar-menu-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: left;
        padding: 1rem;
        position: absolute;
        background-color:var(--color-subtext);
        top: 82px;
        right: 0;
        margin-top: 1rem;
        min-width: 210px;
        border-radius: 5px;
        
    }

    .navbar-menu-container-links p {
        text-decoration: none;
        font-size: x-large;
        margin: 1rem 0;
        cursor: pointer;
    }

    .navbar-menu-container-links a {
        color: white;
    }



.header {
    display: flex;
    flex-direction: row;
}

.header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.header-content h1 {
    font-weight: 800;
    font-size: 58px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.header-content-input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
}

.header-content-input input {
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.header-image{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-image img {
    width: 50%;
}

.header-button{
    -webkit-appearance: none;
            appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 32px;
    margin-top: 2rem;
    text-align: center;
    text-decoration: none;
    vertical-align: left;
    
  }

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }

    .header-content {
        margin: 0 0 3rem;
        align-items: center;
        text-align: center;
    }
    .header-content button {
        align-self: center;
    }
}
.services {
    padding-top: 0%;
}

.services-info h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 75px;
    letter-spacing: -0.04em;
    text-align: center;
}

.services-info p {
    text-align: center;
    margin: 3rem 20rem;
}

.services-items {
    background-color: var(--color-subtext);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
}

.services-items p {
    color: white;
}

.services-items div {
    padding: 1rem;
    text-align: center;
}

@media screen and (max-width: 1050px){
    .services-items{
        flex-wrap: wrap;
    }
    .services-info p{
        margin: 3rem 10rem;
    }
}

@media screen and (max-width: 700px) {
    .services-info p {
        margin: 3rem 5rem;
    }
}
.wrapper h1{
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 75px;
    letter-spacing: -0.04em;
    margin-bottom: 3rem;
}

.reviews{
    display: flex;
    flex-direction: row;
}

.reviews_box{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    border-radius: 1rem;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    padding: 2rem;
}

.reviews_box p{
    text-align: left;
}

.reviews_box_title{
    margin-bottom: 2rem;
}

.reviews_box_person{
    align-items: flex-end;
    margin-top: auto;

}

.reviews_box_person img {
    width: 15%;
    margin-bottom: 2rem;
}

.reviews_box_person_name{
    font-weight: bolder;
}

@media screen and (max-width: 1050px) {
    .reviews{
        display: flex;
        flex-direction: column;
    }
    .reviews_box{
        margin: 1rem;
        text-align: center;
    }
    .reviews_box p{
        text-align: center;
    }
    
}
.experience{
    display: flex;
    flex-direction: column;
}
.experience_items{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    text-align: center;
    background-color: var(--color-subtext);

}
.experience_items img {
    width: 7rem;
}

.experience_items p{
    font-size: large;
}
.experience_items h1{
    font-size: 42px;
}

.cta {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 2rem;
    margin: 0rem;
    background-color: #11702c;
    color: white;
}
.cta button {
    -webkit-appearance: none;
            appearance: none;
    background-color: #fff;
    border: 0.0625rem solid rgba(27, 31, 35, .15);
    border-radius: 0.375rem;
    box-shadow: rgba(27, 31, 35, .1) 0 0.0625rem 0;
    box-sizing: border-box;
    color: #2ea44f;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.25rem;
    padding: 0.625rem 1.75rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    }

  @media screen and (max-width:768px) {
    .experience_items{
        display: flex;
        flex-direction: column;
    }
    .experience_items div{
        padding-top: 3rem;
    }
    .cta h1{
        text-align: center;
        margin-bottom: 1rem;
    }
    .cta{
        flex-direction: column;
    }
  }

.footer{
    text-align: center;
}
body{
    background-color: white;
}
.container{
    padding: 0 12%;
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.top img{
    max-width: 300px;
    border-radius: 50%;
    margin-right: 2rem;
}
.text{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.text h2{
    text-align: left;
}
.content{
    text-align: left;
}
.content h2{
    text-align: center;
}
@media screen and (max-width:1200px) {
    .container{
        padding: 0 2rem;
    }
}

@media screen and (max-width:768px) {
    .top{
        flex-direction: column;
    }
    .top img{
        margin-bottom: 1rem;
    }
    .container{
        padding: 0 2rem;
    }
}


.contact-form-container{
  padding-top: 3rem;
}

.contact-form {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
    margin: auto;
    width: 75%;
}
  
  .contact-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-form input[type="text"], 
  .contact-form input[type="email"], 
  .contact-form textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    border-radius: 3px;
  }
  

  .contact-form button[type="submit"]:hover {
    background-color: var(--color-subtext);
  }
  
  .contact-form .form-message {
    margin-top: 20px;
  }
  
  .contact-form .form-message p {
    margin: 0;
  }
  
  .contact-form .success-message span {
    color: var(--color-darker-green);
  }
  
  .contact-form .error-message span {
    color: red;
  }
  
  @media screen and (max-width:768px) {
    .contact-form{
      margin: 1rem;
      width: auto;
    }
  }
.container{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.box_container{
    text-align: center;
    background-color: rgb(245, 245, 245);
    padding: 2rem;
    margin: 5rem;
    width: 30% auto;
    border-radius: 5px;
}

.box_container h1{
    padding-top: 2rem;
}


.courses_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .courses_list-item {
    width: 48%;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    border-color: #d9d9d9;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .courses_list-item li{
    list-style-position: inside;
    padding-left: 2rem;
    text-indent: -1.5rem;

  }
  
  .courses_list-item ul{
    padding-top: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .courses_list{
        padding: 1rem;
    }
    .courses_list-item {
      width: 100%;

    }
    .courses_list-item li{
    text-indent: -1.5rem;

    }
  }
.container{
    background-color: white;
}
.subtext{
    font-style: italic;
    padding-bottom: 2rem;
}

.sale{
    font-size: smaller;
    padding-top: 1rem;
}

.detail ul{
    display: inline-block;
    text-align: left;
    padding-bottom: 2rem;
}

.detail li{
    list-style-position: inside;
    padding-left: 2rem;
    text-indent: -1.5rem;
}

@media screen and (max-width:768px) {
    p{
        text-align: left;
    }
    .detail li{
        text-align: left;
    }
}
