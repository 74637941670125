.header {
    display: flex;
    flex-direction: row;
}

.header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.header-content h1 {
    font-weight: 800;
    font-size: 58px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.header-content-input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
}

.header-content-input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.header-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-image img {
    width: 50%;
}

.header-button{
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 32px;
    margin-top: 2rem;
    text-align: center;
    text-decoration: none;
    vertical-align: left;
    
  }

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }

    .header-content {
        margin: 0 0 3rem;
        align-items: center;
        text-align: center;
    }
    .header-content button {
        align-self: center;
    }
}