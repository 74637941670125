.container{
    background-color: white;
}
.subtext{
    font-style: italic;
    padding-bottom: 2rem;
}

.sale{
    font-size: smaller;
    padding-top: 1rem;
}

.detail ul{
    display: inline-block;
    text-align: left;
    padding-bottom: 2rem;
}

.detail li{
    list-style-position: inside;
    padding-left: 2rem;
    text-indent: -1.5rem;
}

@media screen and (max-width:768px) {
    p{
        text-align: left;
    }
    .detail li{
        text-align: left;
    }
}