.services {
    padding-top: 0%;
}

.services-info h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 75px;
    letter-spacing: -0.04em;
    text-align: center;
}

.services-info p {
    text-align: center;
    margin: 3rem 20rem;
}

.services-items {
    background-color: var(--color-subtext);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
}

.services-items p {
    color: white;
}

.services-items div {
    padding: 1rem;
    text-align: center;
}

@media screen and (max-width: 1050px){
    .services-items{
        flex-wrap: wrap;
    }
    .services-info p{
        margin: 3rem 10rem;
    }
}

@media screen and (max-width: 700px) {
    .services-info p {
        margin: 3rem 5rem;
    }
}