.contact-form-container{
  padding-top: 3rem;
}

.contact-form {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
    margin: auto;
    width: 75%;
}
  
  .contact-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-form input[type="text"], 
  .contact-form input[type="email"], 
  .contact-form textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    border-radius: 3px;
  }
  

  .contact-form button[type="submit"]:hover {
    background-color: var(--color-subtext);
  }
  
  .contact-form .form-message {
    margin-top: 20px;
  }
  
  .contact-form .form-message p {
    margin: 0;
  }
  
  .contact-form .success-message span {
    color: var(--color-darker-green);
  }
  
  .contact-form .error-message span {
    color: red;
  }
  
  @media screen and (max-width:768px) {
    .contact-form{
      margin: 1rem;
      width: auto;
    }
  }