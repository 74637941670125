.container{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.box_container{
    text-align: center;
    background-color: rgb(245, 245, 245);
    padding: 2rem;
    margin: 5rem;
    width: 30% auto;
    border-radius: 5px;
}

.box_container h1{
    padding-top: 2rem;
}

