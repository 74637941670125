@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(90deg, rgba(30, 190, 88, 1) 0%, rgba(48, 100, 44, 1) 100%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #f2f2f2;
  --color-footer : #efefef;
  --color-blog: #00ffa6;
  --color-text: #161616;
  --color-subtext: #2ea44f;
  --color-darker-green: #11702c;
}