.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    background-color: white;
    /* box-shadow: 0px 2px 10px #C2C2C2; */
}

.navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
}

.navbar-links_logo{
    display: flex;
    flex: 1;
}

.navbar-links_logo img{
    width: 56px;
    margin-right: 1rem;
}

.navbar-links-logo-text{
    display: flex;
    align-self: center;
    font-weight: bold;
    font-size: large;
}

.navbar-links-items{
    display: flex;
    flex-direction: row;
    font-size: medium;
    font-weight: 500;
    gap: 2rem;
}

.navbar-links-items a {
    color: var(--color-text);
}

.navbar-links-items-button{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-menu{
    display: none;
}

.navbar-button button{
      appearance: none;
      background-color: #2ea44f;
      border: 1px solid rgba(27, 31, 35, .15);
      border-radius: 6px;
      box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      font-weight: 300;
      line-height: 20px;
      padding: 10px 28px;
      position: relative;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      white-space: nowrap;
    }

    @media screen and (max-width: 1050px){
        .navbar-links-items {
            display: none;
        }
        .navbar-menu{
            display: flex;
        }
    }

    @media screen and (max-width: 700px){
        .navbar {
            padding: 2rem 4rem;
        }
    }

    .navbar-menu-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: left;
        padding: 1rem;
        position: absolute;
        background-color:var(--color-subtext);
        top: 82px;
        right: 0;
        margin-top: 1rem;
        min-width: 210px;
        border-radius: 5px;
        
    }

    .navbar-menu-container-links p {
        text-decoration: none;
        font-size: x-large;
        margin: 1rem 0;
        cursor: pointer;
    }

    .navbar-menu-container-links a {
        color: white;
    }


