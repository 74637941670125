body{
    background-color: white;
}
.container{
    padding: 0 12%;
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.top img{
    max-width: 300px;
    border-radius: 50%;
    margin-right: 2rem;
}
.text{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.text h2{
    text-align: left;
}
.content{
    text-align: left;
}
.content h2{
    text-align: center;
}
@media screen and (max-width:1200px) {
    .container{
        padding: 0 2rem;
    }
}

@media screen and (max-width:768px) {
    .top{
        flex-direction: column;
    }
    .top img{
        margin-bottom: 1rem;
    }
    .container{
        padding: 0 2rem;
    }
}

