.experience{
    display: flex;
    flex-direction: column;
}
.experience_items{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    text-align: center;
    background-color: var(--color-subtext);

}
.experience_items img {
    width: 7rem;
}

.experience_items p{
    font-size: large;
}
.experience_items h1{
    font-size: 42px;
}

.cta {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 2rem;
    margin: 0rem;
    background-color: #11702c;
    color: white;
}
.cta button {
    appearance: none;
    background-color: #fff;
    border: 0.0625rem solid rgba(27, 31, 35, .15);
    border-radius: 0.375rem;
    box-shadow: rgba(27, 31, 35, .1) 0 0.0625rem 0;
    box-sizing: border-box;
    color: #2ea44f;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.25rem;
    padding: 0.625rem 1.75rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    }

  @media screen and (max-width:768px) {
    .experience_items{
        display: flex;
        flex-direction: column;
    }
    .experience_items div{
        padding-top: 3rem;
    }
    .cta h1{
        text-align: center;
        margin-bottom: 1rem;
    }
    .cta{
        flex-direction: column;
    }
  }
