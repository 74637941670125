.wrapper h1{
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 75px;
    letter-spacing: -0.04em;
    margin-bottom: 3rem;
}

.reviews{
    display: flex;
    flex-direction: row;
}

.reviews_box{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    border-radius: 1rem;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    padding: 2rem;
}

.reviews_box p{
    text-align: left;
}

.reviews_box_title{
    margin-bottom: 2rem;
}

.reviews_box_person{
    align-items: flex-end;
    margin-top: auto;

}

.reviews_box_person img {
    width: 15%;
    margin-bottom: 2rem;
}

.reviews_box_person_name{
    font-weight: bolder;
}

@media screen and (max-width: 1050px) {
    .reviews{
        display: flex;
        flex-direction: column;
    }
    .reviews_box{
        margin: 1rem;
        text-align: center;
    }
    .reviews_box p{
        text-align: center;
    }
    
}